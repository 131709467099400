<template>
  <c-box
    pos="relative"
    w="100%"
    max-width="1270px"
    mx="auto"
    :my="['0', '16px']"
    :bg="['#fff', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['auto', '74vh']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Pilih Program',
        },
        {
          label: 'Informasi Pribadi',
          isCurrentStep: true,
        },
        {
          label: 'Periksa',
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />

    <c-box
      :border="['none', '1px solid #C4C4C4']"
      :border-radius="['none', '12px']"
      :padding="['0 22px', '30px']"
      :max-width="['auto', '900px']"
      margin="auto"
    >
      <BaseText
        size-mobile="18px"
        size-desktop="28px"
        text-align="center"
      >
        Informasi Pribadi
      </BaseText>

      <c-flex
        justify-content="center"
        :mt="['12px', '40px']"
      >
        <c-button
          variant="outline"
          border-radius="1000px"
          :font-size="['14px', '18px']"
          :disabled="dataMixinsLogin.isLoadingLoginWithGoogle"
          font-weight="500"
          :padding="['0 30px','0 40px']"
          :height="['40px','60px']"
          @click="handleGoogle_"
        >
          <inline-svg
            :src="require('@/assets/ic-google.svg')"
            height="20px"
            min-height="20px"
            width="20px"
            min-width="20px"
          />
          <c-box
            as="span"
            padding-left="8px"
          >
            Daftar dengan Google
          </c-box>
        </c-button>
      </c-flex>

      <c-text
        :mt="['12px', '16px']"
        text-align="center"
        :font-size="['12px', '16px']"
        color="neutral.darkGray"
      >
        atau
      </c-text>

      <c-flex
        :gap="['7px', '10px']"
        :mt="['12px', '16px']"
        :mb="['22px', '40px']"
        justify="center"
      >
        <BaseText
          size-mobile="12px"
          size-desktop="16px"
          :text-align="['center', 'start']"
        >
          Sudah punya akun?
        </BaseText>
        <BaseText
          size-mobile="12px"
          size-desktop="16px"
          color="primary.400"
          as="a"
          @click="handleIsOpenModalLogin(true)"
        >
          Login
        </BaseText>
      </c-flex>

      <BaseText
        size-mobile="16px"
        size-desktop="20px"
      >
        Detail Program
      </BaseText>

      <BaseDivider />

      <!-- CARD DETAIL PROGRAM -->
      <c-flex
        :gap="['10px', '30px']"
        align-items="center"
        :margin="['10px 0', '20px 0']"
      >
        <c-image
          v-if="detailProgram?.product?.photoUrl"
          border-radius="12px"
          :width="['70px', '120px']"
          :height="['89px', '153px']"
          object-fit="cover"
          :src="detailProgram?.product?.photoUrl"
          alt="Product Image"
        />
        <c-box width="100%">
          <c-flex
            align-items="center"
            gap="8px"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="28px"
            >
              {{ detailProgram?.product?.name }} <c-text :display="['inline', 'none']">
                {{ detailProgram?.programsService }} ({{ detailProgram?.duration }} Hari)
              </c-text>
            </BaseText>
            <BaseText
              :display="['none', 'inline']"
              size-mobile="16px"
              size-desktop="16px"
            >
              {{ detailProgram?.programsService }} ({{ detailProgram?.duration }} Hari)
            </BaseText>
          </c-flex>

          <router-link
            style="display: flex; gap: 8px; align-items: center;margin-top: 8px;"
            :to="getHandleMore"
          >
            <BaseText
              size-mobile="12px"
              size-desktop="16px"
            >
              Selengkapnya
            </BaseText>
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-right.svg')"
              height="12"
              width="12"
              fill="#333333"
            />
          </router-link>

          <BaseText
            margin-top="8px"
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ formatCurrency(detailProgram?.price || 0) }}
          </BaseText>
        </c-box>
      </c-flex>

      <BaseDivider margin-bottom="16px" />

      <form @submit.prevent="">
        <c-flex
          :direction="['column', 'row']"
          :gap="['0', '20px']"
        >
          <FormInput
            v-model="firstName"
            type="text"
            is-required
            :is-invalid="isInvalidField($v.firstName)"
            label="Nama Depan"
            placeholder="Masukkan nama depan"
            :icon-left-element="require('@/assets/ic-profile.svg')"
            full-width
            :invalid-text="parseErrors('Nama depan', $v.firstName)"
            @blur="$v.firstName.$touch"
          />

          <FormInput
            v-model="lastName"
            type="text"
            is-required
            :is-invalid="isInvalidField($v.lastName)"
            label="Nama Belakang"
            placeholder="Masukkan nama belakang"
            :icon-left-element="require('@/assets/ic-profile.svg')"
            full-width
            :invalid-text="parseErrors('Nama belakang', $v.lastName)"
            @blur="$v.lastName.$touch"
          />
        </c-flex>

        <FormInput
          v-model="phoneNumber"
          type="text"
          is-required
          :is-invalid="isInvalidField($v.phoneNumber)"
          label="Nomor Telepon"
          placeholder="Masukkan nomor telepon"
          :icon-left-element="require('@/assets/ic-phone.svg')"
          icon-left-element-color="#888888"
          :invalid-text="parseErrors('Nomor telepon', $v.phoneNumber)"
          @blur="$v.phoneNumber.$touch"
        />

        <c-flex
          align-items="start"
          :gap="['4px', '20px']"
          mb="24px"
        >
          <FormInput
            v-model="email"
            type="text"
            is-required
            :is-invalid="isInvalidField($v.email)"
            label="Email"
            placeholder="Masukkan email"
            :icon-left-element="require('@/assets/ic-mail.svg')"
            icon-left-element-color="#888888"
            is-without-margin-bottom
            full-width
            :invalid-text="parseErrors('Email', $v.email)"
            :is-disabled="dataMixinsModalOtp.isValidOtp === true"
            @blur="$v.email.$touch"
            @trigger-change="resetCountDown"
          />
          <BaseButton
            size="large"
            variant="contained"
            :h="['48px','62px']"
            :min-width="['73px', '200px']"
            :margin-top="['26px', '28px']"
            :disabled="$v.email.$invalid || dataMixinsModalOtp.isValidOtp === true"
            :is-loading="isLoadingEmailOtpSend"
            @click="handleIsOpenModalOtp(true)"
          >
            <BaseText
              size-mobile="14px"
              size-desktop="14px"
              as="span"
            >
              Verifikasi
            </BaseText>
          </BaseButton>
        </c-flex>

        <c-flex
          :direction="['column', 'row']"
          :gap="['0', '20px']"
        >
          <FormInput
            v-model="password"
            type="password"
            is-required
            :is-invalid="isInvalidField($v.password)"
            label="Kata Sandi"
            placeholder="Masukkan kata sandi"
            :icon-left-element="require('@/assets/ic-padlock.svg')"
            full-width
            :invalid-text="parseErrors('Kata sandi', $v.password)"
            @blur="$v.password.$touch"
          />

          <FormInput
            v-model="confirmPassword"
            type="password"
            is-required
            :is-invalid="isInvalidField($v.confirmPassword)"
            label="Konfirmasi Kata Sandi"
            placeholder="Masukkan konfirmasi kata sandi"
            :icon-left-element="require('@/assets/ic-padlock.svg')"
            full-width
            :invalid-text="parseErrors('Konfirmasi kata sandi', $v.confirmPassword)"
            @blur="$v.confirmPassword.$touch"
          />
        </c-flex>
        <c-flex
          :gap="['4px','16px']"
        >
          <BaseButton
            size="large"
            variant="outlined"
            w="100%"
            border-radius="1000px"
            @click="handlePrevious"
          >
            Kembali
          </BaseButton>
          <BaseButton
            size="large"
            variant="contained"
            w="100%"
            border-radius="1000px"
            :disabled="$v.$invalid"
            @click="handleNext"
          >
            Selanjutnya
          </BaseButton>
        </c-flex>
      </form>

      <ModalLogin
        :is-open="isOpenModalLogin"
        @handle-is-open="handleIsOpenModalLogin"
      />

      <ModalOtp
        :is-open="isOpenModalEmailOtp"
        @handle-is-open="setIsOpenModalEmailOtp"
        @trigger-result-verify="isVerifiedEmail = $event"
      />

      <ModalInfo
        for="errorEmailAlreadyRegistered"
        :with-button-close="false"
        :is-open="isOpenModalErrorEmailAlreadyRegistered"
        @close="isOpenModalErrorEmailAlreadyRegistered = false"
      />
    </c-box>
  </c-box>
</template>

<script>
import ModalLogin from '@/views/buy/_widgets/modal-login.vue'
import ModalOtp from '@/views/buy/_widgets/modal-otp.vue'
import Stepper from '@/components/elements/stepper.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import { required, email, numeric } from 'vuelidate/lib/validators'
import passwordValidator from '@/utils/vuelidate-custom-validator/password-validator'
import sameAsWithCustomField from '@/utils/vuelidate-custom-validator/same-as-with-custom-field'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import mixinsModalOtp from '@/views/buy/_widgets/mixins-modal-otp'
import mixinsLogin from '@/views/buy/_widgets/mixins-login'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import mixinsUseRequest from '@/mixins/mixins-use-request'
import ModalInfo from '@/components/widgets/modal-info/index.vue'
import isLiteral from '@/utils/vuelidate-custom-validator/is-literal'
import BaseDivider from '@/components/elements/base-divider.vue'
import { formatCurrency } from '../../utils/format-currency'
// import { DUMMY_QUERY_PARAMS } from '@/utils/dummy-data'

export default {
  name: 'Buy2Page',
  components: { BaseDivider, ModalInfo, BaseText, BaseButton, FormInput, Stepper, ModalOtp, ModalLogin },
  mixins: [mixinsModalOtp, mixinsLogin, mixinsUseRequest],
  beforeRouteLeave(to, from, next) {
    if (to.name === 'client.pricing') {
      next({ name: 'client.buy.step-1', query: from.query })
      return
    }
    next()
  },
  data() {
    return {
      detailProgram: null,
      isModalCouponLearnMore: false,
      isOpenModalLogin: false,
      isOpenModalOtp: false,
      isOpenModalErrorEmailAlreadyRegistered: false,
      isLoadingHandleNext: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      isVerifiedEmail: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    getHandleMore() {
      if (this.detailProgram?.isKonsultasi === 1) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (this.detailProgram?.isExtend === 1) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: this.detailProgram?.product?.slug },
      }
    },
  },
  mixinsUseRequest_registerKey: ['handleLogin'],
  validations: {
    firstName: { required },
    lastName: { required },
    phoneNumber: { required, numeric },
    email: { required, email },
    password: passwordValidator,
    confirmPassword: {
      required,
      sameAsWithCustomField: sameAsWithCustomField('password', 'kata sandi'),
    },
    isVerifiedEmail: { isLiteral: isLiteral(true) },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push({
        name: 'client.buy.step-3',
        query: this.$route.query, // DUMMY_QUERY_PARAMS,
      })
    }
  },
  async mounted() {
    this.$posthog.capture('visitor want to signup')
    const res = await this.getProductServiceId(this.$route.query?.product_service_id)
    this.detailProgram = res?.data
  },
  methods: {
    formatCurrency,
    ...mapMutations({
      setUser: 'auth/setUser',
      setToken: 'auth/setToken',
      setRefreshToken: 'auth/setRefreshToken',
      setIsGoogle: 'auth/setIsGoogle',
      setRedirect: 'auth/setRedirect',
    }),
    ...mapActions({
      signup: 'auth/signup',
      getProductServiceId: 'general/getProductServiceId',
    }),
    isInvalidField,
    parseErrors,
    handleGoogle_() {
      this.setRedirect({
        name: 'client.buy-program.step-2',
        query: this.$route.query,
      })
      this.handleLoginWithGoogle()
    },
    handleIsOpenModalLogin(isOpen) {
      this.isOpenModalLogin = isOpen
    },
    async handleIsOpenModalOtp() {
      try {
        await this.handleEmailOtpSend({ email: this.email })
        this.$posthog.capture('visitor have signup')
      } catch (e) {
        if (parseErrorCatch(e) === 'Email is already registered!') {
          this.isOpenModalErrorEmailAlreadyRegistered = true
          return
        }

        this.$toast({
          title: 'Failed',
          description: parseErrorCatch(e),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      }
    },
    handlePrevious() {
      this.$router.push({ 
        name: 'client.buy.step-1',
        query: this.$route.query,
      })
    },
    async handleNext() {
      this.isLoadingHandleNext = true
      try {
        await this.signup({
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phoneNumber,
          email: this.email,
          password: this.password,
          otp: this.dataMixinsModalOtp.otp,
        })
        await this.mixinsLogin_handleLoginWithEmail({
          email: this.email,
          password: this.password,
        })
        this.$posthog.capture('visitor success signup')
        this.$router.push({
          name: 'client.buy.step-3',
          query: this.$route.query, //DUMMY_QUERY_PARAMS,
        })
      } catch (e) {
        this.isOpenModalErrorEmailAlreadyRegistered = true
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isLoadingHandleNext = false
      }
    },
  },
}
</script>
